import { CommonModule } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';
import { DialogService } from 'primeng/dynamicdialog';
import { ProductQuickViewComponent } from '../../../amm-pages/product-quick-view/product-quick-view.component';
import { forceLogin } from '../../decorators';
import { IntersectionObserverDirective } from '../../directives';
import { Product } from '../../models/catalog.model';
import {
  AuthService,
  StorageService,
  VariantSelectionService,
} from '../../services';
import { ShoppingInfoStore } from '../../stores';
import { resizeAllGridItems } from '../../utilitys';
import { AmmQtyAdjusterComponent } from '../amm-qty-adjuster/amm-qty-adjuster.component';

@Component({
  selector: 'amm-item-card',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AutoFocusModule,
    AmmQtyAdjusterComponent,
    ProductQuickViewComponent,
    IntersectionObserverDirective,
  ],
  templateUrl: './item-card.component.html',
  styleUrl: './item-card.component.scss',
  providers: [],
})
export class ItemCardComponent {
  public showAdjuster: boolean = false;
  @Input() product!: Product;

  get mainImg() {
    return this.product.mainImage?.uri || '';
  }

  public shopInfoStore = inject(ShoppingInfoStore);
  public currentQty = computed(
    () =>
      this.shopInfoStore
        .shoppingCarts()
        .find((x) => x.code === this.product.code)?.qty || 0,
  );

  private currentQty$ = toObservable(this.currentQty);
  public storageService = inject(StorageService);
  private dialogService = inject(DialogService);
  private messageService = inject(MessageService);
  private authService = inject(AuthService);

  private router: Router = inject(Router);
  protected variantSelectService = inject(VariantSelectionService);

  ngOnInit() {
    this.currentQty$.subscribe((v) => {
      if (v == 0) {
        this.showAdjuster = false;
      }
    });
  }

  onClick(): void {
    this.router.navigate([
      'home',
      {
        outlets: {
          mainBody: [
            'details',
            (this.product.variantMerchs?.length || 0) > 0
              ? this.product.code
              : this.product.id,
          ],
        },
      },
    ]);
  }

  getFavoriteClass() {
    const favorites = computed(() => this.shopInfoStore.favorites());
    const isInProductFavorite =
      favorites().find((x) => x.merchCode === this.product.code)?.action ===
      'ADD';
    this.product.favorite = isInProductFavorite;
    if (this.product.favorite) {
      return 'quick-action-favorite-selected';
    } else {
      return 'quick-action-favorite';
    }
  }

  onFavorite(event: PointerEvent | MouseEvent) {
    event.stopImmediatePropagation();
    this.addFavorite(this.authService, this.dialogService);
  }
  @forceLogin()
  addFavorite(authService: AuthService, dialogService: DialogService) {
    this.product.favorite = !this.product.favorite;
    if (this.product.favorite) {
      this.shopInfoStore.increaseFavorite(this.product.code || '');
      this.messageService.add({
        severity: 'contrast',
        detail: 'Added to favorite',
        key: 'product-operation-toast',
      });
    } else {
      this.shopInfoStore.decreaseFavorite(this.product.code || '');
      this.messageService.add({
        severity: 'contrast',
        detail: 'Removed from favorite',
        key: 'product-operation-toast',
      });
    }
  }

  onShopCartClick(event: PointerEvent | MouseEvent) {
    console.log(event);
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.product.isMainVariant) {
      // Open quick shop action
      const quickProductViewDialogRef = this.dialogService.open(
        ProductQuickViewComponent,
        {
          showHeader: true,
          data: {
            product: this.product,
          },
          closable: true,
          style: {
            'max-width': '1000px',
            'max-height': '720px',
            'border-radius': '12px',
          },
          contentStyle: {
            'border-radius': '12px',
          },
        },
      );
      quickProductViewDialogRef.onClose.subscribe(() => {
        this.variantSelectService.resetMaps();
      });
    } else {
      this.showAdjuster = true;
    }
  }
  onQtyDecrease(qty: number) {
    this.shopInfoStore.decreaseShopingCart(this.product.code || '');
    this.messageService.add({
      severity: 'contrast',
      detail: 'Removed from ShopCart',
      key: 'product-operation-toast',
    });
  }
  onIncrease(qty: number) {
    this.shopInfoStore.increaseShopingCart(this.product.code || '');
    this.messageService.add({
      severity: 'contrast',
      detail: 'Added to ShopCart',
      key: 'product-operation-toast',
    });
  }
  onInterSecting(isIntersecting: boolean) {
    // console.log(isIntersecting);
    // if (!isIntersecting) {
    //   this.globalNavIndicatorStore.enableNavIndicator();
    // } else {
    //   this.globalNavIndicatorStore.disableNavIndicator();
    // }
  }
  onImgLoad($event: any) {
    resizeAllGridItems('item', 'mansory', '.content', document, window);
  }
}

<div class="content cursor-pointer" (click)="onClick()">
  <div class="photo-container">
    <div class="flex flex-col gap-2 z-10 quick-action-wrapper">
      @if (product.isMainVariant) {
        <div
          class="flex justify-center items-center cursor-pointer quick-action-variant-shopcart"
          (click)="onShopCartClick($event)"
        >
          <span>See options</span>
        </div>
      } @else {
        @if (showAdjuster) {
          <amm-qty-adjuster
            [ngStyle]="{
              display: currentQty() > 0 || showAdjuster ? 'flex' : 'none',
            }"
            (onDecrease)="onQtyDecrease($event)"
            (onIncrease)="onIncrease($event)"
            [shopQty]="currentQty()"
          ></amm-qty-adjuster>
        } @else {
          <div
            class="flex justify-center items-center cursor-pointer quick-action-shoppingcart"
            (click)="onShopCartClick($event)"
          >
            <span class="material-symbols-outlined text-4xl"
              >shopping_cart</span
            >
          </div>
        }
      }
      <div
        class="flex justify-center items-center cursor-pointer"
        [class]="getFavoriteClass()"
        (click)="onFavorite($event)"
      >
        <span class="material-symbols-outlined"> favorite </span>
      </div>
    </div>
    <img
      class="listing-photo"
      (click)="onClick()"
      [src]="mainImg"
      alt="Exterior photo of {{ product.title }}"
      (load)="onImgLoad($event)"
    />
    <p class="photo-tag">
      @if (product.isMainVariant) {
        {{
          product.variantMinPrice?.sign
            ? product.variantMinPrice?.sign
            : product.variantMaxPrice?.sign || "$"
        }}
        {{
          ((product.variantMinPrice?.amount
            ? product.variantMinPrice?.amount
            : product.variantMaxPrice?.amount
          ) | number: "1.2-2") + " +"
        }}
      } @else {
        {{ product.curPrice?.sign || "$" }}
        {{ product.curPrice?.amount || 0 | number: "1.2-2" }}
      }
    </p>
  </div>

  <div class="info-container">
    <h2 class="listing-title">
      {{ product.title }}
    </h2>
    <p class="listing-subtitle">
      {{
        product.store && product.store.storeName ? product.store.storeName : ""
      }}
    </p>
  </div>
</div>

import { AfterViewInit, Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
import { resizeAllGridItems } from '../../utilitys';

@Component({
  selector: 'amm-product-card-skeleton-loader',
  standalone: true,
  imports: [SkeletonModule],
  templateUrl: './product-card-skeleton-loader.component.html',
  styleUrl: './product-card-skeleton-loader.component.scss',
})
export class ProductCardSkeletonLoaderComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    if (typeof document != 'undefined' && typeof window != 'undefined') {
      resizeAllGridItems('item', 'mansory', '.content', document, window);
    }
  }
}

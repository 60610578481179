<div class="flex flex-col gap-3 h-full mt-3 p-4">
  <div class="content relative">
    <div class="absolute z-10 quick-action-wrapper">
      <div class="flex flex-col gap-2">
        <p-skeleton
          borderRadius="50%"
          size="40px"
          styleClass="quick-action-placeholder"
        />
        <p-skeleton
          borderRadius="50%"
          size="40px"
          styleClass="quick-action-placeholder"
        />
      </div>
    </div>
    <p-skeleton
      width="100%"
      height="350px"
      borderRadius="16px"
      styleClass="img-wrapper"
    />
    <div class="absolute z-10 price-tag"></div>
  </div>
  <p-skeleton width="100%" height="20px" styleClass="intro-wrapper" />
</div>

/**
 * Resize single item in the
 * grid
 * @param item
 */
export function resizeGridItem(
  item: any,
  gridClassName: string,
  mansoryItemClassName: string,
  document: Document,
  window: Window,
  rowHeighGridProperty: string = 'grid-auto-rows',
  rowGapGridProperty: string = 'grid-row-gap',
) {
  const grid = document.getElementsByClassName(gridClassName)[0];
  const rowHeight = parseInt(
    window.getComputedStyle(grid).getPropertyValue(rowHeighGridProperty),
  );
  const rowGap = parseInt(
    window.getComputedStyle(grid).getPropertyValue(rowGapGridProperty),
  );
  const rowSpan = Math.ceil(
    (item.querySelector(mansoryItemClassName).getBoundingClientRect().height +
      rowGap) /
      (rowHeight + rowGap),
  );
  item.style.gridRowEnd = 'span ' + rowSpan;
}
export function resizeAllGridItems(
  classNameToResize: string,
  gridClassName: string,
  mansoryItemClassName: string,
  document: Document,
  window: Window
) {
  const allItems = document.getElementsByClassName(classNameToResize);
  for (let x = 0; x < allItems.length; x++) {
    resizeGridItem(allItems[x], gridClassName, mansoryItemClassName, document, window);
  }
}
